<template>
  <div class="main">
    <div class="modal">
      <div class="modal-left">
        <div class="block-close">
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 16 16"
            fill="#816AA6"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.4697 12.5303C11.7626 12.8232 12.2374 12.8232 12.5303 12.5303C12.8232 12.2374 12.8232 11.7626 12.5303 11.4697L9.06066 8L12.5303 4.53033C12.8232 4.23744 12.8232 3.76256 12.5303 3.46967C12.2374 3.17678 11.7626 3.17678 11.4697 3.46967L8 6.93934L4.53033 3.46967C4.23744 3.17678 3.76256 3.17678 3.46967 3.46967C3.17678 3.76256 3.17678 4.23744 3.46967 4.53033L6.93934 8L3.46967 11.4697C3.17678 11.7626 3.17678 12.2374 3.46967 12.5303C3.76256 12.8232 4.23744 12.8232 4.53033 12.5303L8 9.06066L11.4697 12.5303Z"
              fill="#816AA6"
            ></path>
          </svg>
        </div>
        <div class="bonus-card">
          <div class="bonus-img">
            <img src="@/assets/img/banner.webp" alt="monkey" />
          </div>
          <div class="bonus-desc">
            <h3>Забирай бонус</h3>
            <h1><span>150%</span>+<span>250FS</span></h1>
          </div>
        </div>
      </div>
      <div class="registration-form modal-right">
        <div class="form-header">
          <h2>Вход</h2>
          <h2 class="active">Регистрация</h2>
        </div>
        <form
          action="#"
          id="registrationForm"
          :class="{'form_disabled': isRequestSending}"
          @submit.prevent="submitForm"
        >
          <div class="form-group" style="width: 100%">
            <input type="tel" id="phone" value="+7" required v-model="phone" />
            <div class="inputIcon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="20px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#816AA6"
              >
                <path
                  d="M798-120q-125 0-247-54.5T329-329Q229-429 174.5-551T120-798q0-18 12-30t30-12h162q14 0 25 9.5t13 22.5l26 140q2 16-1 27t-11 19l-97 98q20 37 47.5 71.5T387-386q31 31 65 57.5t72 48.5l94-94q9-9 23.5-13.5T670-390l138 28q14 4 23 14.5t9 23.5v162q0 18-12 30t-30 12ZM241-600l66-66-17-94h-89q5 41 14 81t26 79Zm358 358q39 17 79.5 27t81.5 13v-88l-94-19-67 67ZM241-600Zm358 358Z"
                />
              </svg>
            </div>
          </div>
          <div class="form-group" style="width: 100%">
            <input
              type="password"
              id="password"
              placeholder="Введите свой пароль"
              required
              v-model="password"
            />
            <div class="inputIcon">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M11 11L6 16"
                  stroke="#816AA6"
                  stroke-width="2"
                  stroke-linecap="round"
                ></path>
                <path
                  d="M7 17L6 16"
                  stroke="#816AA6"
                  stroke-width="2"
                  stroke-linecap="round"
                ></path>
                <path
                  d="M10 16L8 14"
                  stroke="#816AA6"
                  stroke-width="2"
                  stroke-linecap="round"
                ></path>
                <circle
                  cx="14"
                  cy="10"
                  r="3"
                  stroke="#816AA6"
                  stroke-width="2"
                ></circle>
              </svg>
            </div>
            <div class="passwordShow">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M12 8.5C16.5 8.5 18 12 18 12C18 12 16.5 15.5 12 15.5C7.5 15.5 6 12 6 12C6 12 7.5 8.5 12 8.5Z"
                  stroke="#816AA6"
                  stroke-width="2"
                  stroke-linecap="round"
                ></path>
                <rect
                  x="6.34326"
                  y="16.2993"
                  width="14"
                  height="2"
                  rx="1"
                  transform="rotate(-45 6.34326 16.2993)"
                  fill="#816AA6"
                ></rect>
              </svg>
            </div>
          </div>

          <button type="submit" class="submit-btn">Регистрация</button>

          <div class="form-group promo-code">
            <input type="text" id="promo" v-model="promocode" />
            <div class="inputIcon">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M12.2169 5.00793C12.3039 4.72326 12.4655 4.44714 12.6977 4.18349L12.6924 4.18045C12.2401 3.91708 11.7202 3.96713 11.2784 4.21682C11.4865 4.47716 11.6322 4.744 11.7124 5.01391C11.8806 4.99762 12.0486 4.99571 12.2169 5.00793Z"
                  fill="#816AA6"
                ></path>
                <path
                  d="M11.5293 7.96259L8.96465 9.44329L11.4176 10.848C11.7738 11.0511 12.205 11.0511 12.5425 10.848L14.9806 9.4741L12.3845 7.97525C12.1002 8.01253 11.8121 8.00861 11.5293 7.96259Z"
                  fill="#816AA6"
                ></path>
                <path
                  d="M8 10.3112V13.4912L5.01167 11.777L5.01926 9.47212C5.01926 9.10901 5.44965 8.88867 5.7531 9.06877L8 10.3112Z"
                  fill="#816AA6"
                ></path>
                <path
                  d="M16 13.4596L18.9928 11.7193L19 9.54577C19 9.19953 18.5827 8.95451 18.2662 9.14233L16 10.3651V13.4596Z"
                  fill="#816AA6"
                ></path>
                <path
                  d="M7.96055 8.8683L8.98402 8.2774C7.95351 8.33978 7.41564 7.36785 6.7069 6.80005L6.24375 7.06176C5.91001 7.22603 5.94857 7.6798 6.22493 7.87441L7.96055 8.8683Z"
                  fill="#816AA6"
                ></path>
                <path
                  d="M15.0367 8.35179L15.9928 8.90376L17.7539 7.91138C18.0632 7.75916 18.0919 7.25596 17.7727 7.0986L17.3473 6.85429C16.5753 7.32255 15.9848 8.25032 15.0367 8.35179Z"
                  fill="#816AA6"
                ></path>
                <path
                  d="M8 14.6777L5.00784 12.9404L5 15.3205C5 15.7971 5.27034 16.2372 5.69517 16.4755L8 17.7639V14.6777Z"
                  fill="#816AA6"
                ></path>
                <path
                  d="M18.989 12.8853L16 14.6451V17.7479L18.3242 16.4393C18.7298 16.2193 18.9807 15.816 18.9807 15.3759L18.989 12.8853Z"
                  fill="#816AA6"
                ></path>
                <path
                  d="M12.5037 16.4697L15 15V18.0771L12.4889 19.4909C12.1853 19.6619 11.8146 19.6625 11.5104 19.4924L9 18.0892V15.0246L11.4942 16.4728C11.8066 16.6542 12.1925 16.653 12.5037 16.4697Z"
                  fill="#816AA6"
                ></path>
                <path
                  d="M9 10.8643L11.5221 12.2589C11.82 12.4236 12.1812 12.4255 12.4808 12.2638L15 10.9046V14.0412L12.4992 15.4954C12.1902 15.6751 11.8089 15.6762 11.4989 15.4984L9 14.0649V10.8643Z"
                  fill="#816AA6"
                ></path>
                <g opacity="0.5">
                  <path
                    d="M13 6.5C13 6.77614 12.5523 7 12 7C11.4477 7 11 6.77614 11 6.5C11 6.22386 11.4477 6 12 6C12.5523 6 13 6.22386 13 6.5Z"
                    fill="#816AA6"
                  ></path>
                  <path
                    d="M8.24895 5.80466C8.76107 5.52233 9.72922 5.36783 10.3775 5.59153C10.5737 5.39003 10.8201 5.24505 11.0837 5.15095C9.94221 3.47376 6.76097 2.88337 5.20233 4.32647C4.3672 5.0997 6.32315 5.14694 8.07011 6.54436C8.8842 7.40557 9.71529 7.52374 10.3072 7.33132C10.1354 7.12831 10 6.85513 10 6.5001L10.0001 6.48827C9.10934 5.63804 8.24895 5.80466 8.24895 5.80466Z"
                    fill="#816AA6"
                  ></path>
                  <path
                    d="M13.5018 5.47979C13.305 5.31504 13.074 5.19857 12.8298 5.12201C14.0567 3.4932 17.2911 3.11743 18.732 4.68609C19.4965 5.51836 17.5631 5.42154 15.7321 6.68608C14.9565 7.3985 14.2131 7.5205 13.6575 7.37145C13.9173 7.08609 14.0402 6.70205 13.9879 6.31977C14.7578 5.69748 15.4563 5.66154 15.4563 5.66154C15.0234 5.33541 14.1365 5.21261 13.5018 5.47979Z"
                    fill="#816AA6"
                  ></path>
                </g>
              </svg>
            </div>
          </div>
        </form>

        <div class="form-check">
          <label class="perm-check">
            <p>
              Регистрируясь, ты соглашаешься, что тебе 18+ лет, и соглашаешься
              с нашими <a href="#">Условиями использования</a>
            </p>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification'
import axios from 'axios'

export default {
  name: 'landing-page',
  data () {
    return {
      phone: '+7',
      password: '',
      country: 'RU',
      currency: 'RUB',
      promocode: 'TOR',
      checkbox: false,
      isRequestSending: false
    }
  },
  methods: {
    submitForm () {
      if (this.isRequestSending) {
        return
      }

      this.isRequestSending = true

      const data = {
        user: {
          country: this.country,
          currency: this.currency,

          bonus_code: this.promocode,
          captcha: '',
          parent_refcode: '',
          profile_attributes: {
            age_acceptance: true,
            terms_acceptance: true,
            game_currency: this.currency,
            receive_newsletters: false,
            receive_promos: false,
            last_name: '',
            first_name: '',
            receive_sms_promos: false,
            nickname: '',
            currency: this.currency,
            language: 'ru',
            country: this.country
          },
          phone: {
            country: this.country,
            number: `${this.phone}`
          },
          password: this.password,
          password_confirmation: this.password
        },
        lang: 'ru'
      }

      console.log(data)

      this.fetchData(data)
    },
    fetchData (data) {
      axios.post('/script.php', data, {
        headers: { 'Content-Type': 'application/json;charset=utf-8' }
      })
        .then((response) => {
          this.isRequestSending = false

          if (response.status === 200 || response.statusText === 'OK') {
            if (response.data) {
              const { token, url } = response.data

              if (token && token.length) {
                useToast().success('Успешная регистрация! Перенаправление...')
                document.location.href = url

                return
              }
            }

            useToast().error('Ошибка при регистрации')

            return
          }

          console.log('error', response)
          useToast().error('Ошибка при отправке запроса')
        })
        .catch((error) => {
          this.isRequestSending = false
          console.log('request error', error)
          useToast().error('Ошибка при отправке запроса')
        })
    }
  }
}
</script>

<style src="./style.scss" lang="scss"></style>
